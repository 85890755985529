import React, { useState } from "react";
import { Link } from "react-router-dom";
import icoClose from "../icones/close.svg";
import projetsData from "./projetData";

function Projets() {
    const [visible, setVisible] = useState(false);
    function afficherDetail(id) {
        const projetId = document.getElementById(id);
        if (visible === false) {
            projetId.setAttribute("style", "display: flex");
            setVisible(true);
        }
    }
    function closeDetail(id) {
        const projetId = document.getElementById(id);
        if (visible === true) {
            projetId.setAttribute("style", "display: none");
            setVisible(false);
        }
    }

    return (
        <section className="container_projets" id="projets">
            <h2 className="title">Projets et Réalisations</h2>
            <p className="projets_text">
                Découvrez tous les projets que j'ai réalisés au fil de mon
                apprentissage et au-delà.
            </p>
            <p className="projets_text">
                Certains des projets sont également consultable sur mon{" "}
                <Link
                    to={"https://github.com/Fseymortier"}
                    target="_blank"
                    className="linkGit"
                >
                    GitHub
                </Link>
                .
            </p>
            <div className="container_items_projets">
                {projetsData.map((projet) => (
                    <div key={projet.id}>
                        <div
                            onClick={() => afficherDetail(projet.id)}
                            className={`items_projets ${projet.name}`}
                        ></div>
                        <span
                            id={projet.id}
                            className="container_projets_details"
                        >
                            <span className="projets_details">
                                <img
                                    src={icoClose}
                                    onClick={() => closeDetail(projet.id)}
                                    className="closeDetail"
                                    alt="une croix"
                                />
                                <h3 className="title_projets_details">
                                    {projet.titre}
                                </h3>
                                <p className="textDescription">
                                    {projet.description}
                                </p>
                                <div className="containerLinks">
                                    {projet.liens.map((lien) => (
                                        <Link
                                            key={lien.name}
                                            to={lien.url}
                                            target="_blank"
                                            className="linksProjet"
                                        >
                                            {lien.name}
                                        </Link>
                                    ))}
                                </div>
                                <span className="container_techno">
                                    {projet.techno.map((element) => (
                                        <p
                                            key={element}
                                            className={`name_techno ${element.toLowerCase()}`}
                                        >
                                            {element}
                                        </p>
                                    ))}
                                </span>
                            </span>
                        </span>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Projets;
